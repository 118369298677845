import React from "react";
import {ChartComponentProps, Line} from "react-chartjs-2";
import {GraphData, chartLineOptions} from "../../../utils/chart_options";

interface ChartLineProps extends ChartComponentProps {
    data: GraphData | undefined;
}

const ChartLine: React.FC<ChartLineProps> = ({data, ...rest}) => {
    if (!data) return <></>;

    return <Line data={data} options={chartLineOptions} {...rest}/>;
};

export default ChartLine;
