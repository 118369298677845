import moment from "moment";
import numeral from "numeral";
import {Formatter} from "../common/utils/Formatter";

export type GraphDataset = {
    label?: string;
    borderWidth?: number;
    borderColor?: string;
    backgroundColor: string | string[];
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointRadius?: number;
    data: number[];
};

export type GraphData = {
    labels: string[];
    datasets: GraphDataset[];
}

export const chartLineOptions = {
    barRoundness: 1,
    elements: {
        point: {
            pointStyle: "rectRounded",
            hoverRadius: 10
        }
    },
    scales: {
        xAxes: [{
            type: "time",
            offset: false,
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            gridLines: {display: false}
        }],
        yAxes: [{
            stacked: true,
            ticks: {
                callback: function(value: number) {
                    return Formatter.formatNumber(value);
                }
            }
        }]
    },
    tooltips: {
        mode: "index",
        position: "nearest",
        displayColors: true,
        callbacks: {
            title: (tooltipItem: any) => moment(tooltipItem[0].xLabel).format("DD/MM/YYYY"),
            label: function(tooltipItem: any, data: any) {
                return  " " + data.datasets[tooltipItem.datasetIndex].label + ": " + numeral(tooltipItem.yLabel).format("0,0").replaceAll(",", " ");
            }
        }
    },
    legend: false,
    responsive: true
};

export const CHART_DATASET_DEFAULT_STYLES = {
    borderWidth: 2,
    borderColor: "#16D2A585",
    backgroundColor: "#16D2A540",
    pointBackgroundColor: "#16D2A5",
    pointBorderColor: "#16D2A5",
    pointRadius: 4
};
