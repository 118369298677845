import React, {FunctionComponent} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {goToLogin} from "../../common/utils/helper";
import {PORTAL} from "../../utils/Portal";
import {Authorization} from "../../api/model/account/Authorization";
import {Account} from "../../common/api/model/account/Account";
import {pathAccess} from "../../common/utils/constants";

interface PrivateRouteProps extends RouteProps {
    component: FunctionComponent;
    path: string;
    requiredAuthorization?: Authorization;
}

class PrivateRoute extends Route<PrivateRouteProps> {
    state: { loaded: boolean; account: Account | undefined } = {
        loaded: false,
        account: undefined
    };
    _isMounted = false;

    constructor(props: any) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        (async () => {
            try {
                const isLogged = await session.isLogged();
                if (!isLogged) {
                    this.setState({loaded: true});
                    return;
                }

                const account = await session.getAccount();
                if (!account) {
                    this.setState({loaded: true});
                    return;
                }
                this.setState({loaded: true, account: account});
            } catch {
                this.setState({loaded: true});
            }
        })();
    }

    componentDidUpdate() {
        this._isMounted = true;
        session.on(SirdataApiEvent.eventUnauthorizedRequest, () => {
            session.clean();
            this._isMounted && this.setState({loaded: true});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {component, path, requiredAuthorization, ...rest}: any = this.props;
        const {loaded, account} = this.state;

        if (!loaded) return <></>;
        if (account) {
            if (!account.hasAccess(PORTAL.origin.name)) {
                return <Redirect to={pathAccess}/>;
            }
            if (requiredAuthorization && !account.hasAuthorization(requiredAuthorization)) {
                return <Redirect to={PORTAL.defaultPath}/>;
            }
            return <Route {...rest} exact path={path} component={component}/>;
        } else {
            goToLogin();
        }
    };
}

export default PrivateRoute;
