import React from "react";
import {Button, EmptyContentMessage, MainContentStyle, SvgError404} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {PORTAL} from "../../utils/Portal";
import {useHistory} from "react-router-dom";
import {MainContent, Wrapper} from "../component/widget";
import {TranslationCommonFile} from "../utils/constants";
import {MainHeader} from "../component/snippet";

function Error404() {
    const {t: textError} = useTranslation(TranslationCommonFile.ERRORS);
    const history = useHistory();

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <EmptyContentMessage
                    svg={SvgError404}
                    message={textError("error_404.text")}
                >
                    <Button onClick={() => history.push(PORTAL.defaultPath)}>
                        {textError("error_404.back_to_home")}
                    </Button>
                </EmptyContentMessage>
            </MainContent>
        </Wrapper>
    );
}

export default Error404;
