import React from "react";
import {useTranslation} from "react-i18next";
import {PORTAL} from "../../utils/Portal";
import {useHistory} from "react-router-dom";
import {MainContent, Wrapper} from "../component/widget";
import {Button, EmptyContentMessage, MainContentStyle, SvgError500} from "@sirdata/ui-lib";
import {TranslationCommonFile} from "../utils/constants";
import {MainHeader} from "../component/snippet";

function Error500() {
    const {t: textErrors} = useTranslation(TranslationCommonFile.ERRORS);
    const history = useHistory();

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <EmptyContentMessage
                    svg={SvgError500}
                    message={textErrors("error_500.text")}
                >
                    <Button onClick={() => history.push(PORTAL.defaultPath)}>
                        {textErrors("error_500.back_to_home")}
                    </Button>
                </EmptyContentMessage>
            </MainContent>
        </Wrapper>
    );
}

export default Error500;
