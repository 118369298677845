import React, {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {useHistory, useLocation} from "react-router-dom";
import {Action, Icon, MainHeaderItem} from "@sirdata/ui-lib";
import {onKeyEnter} from "../../utils/form";
import {TranslationCommonFile} from "../../utils/constants";
import {QueryString} from "../../api/model/QueryString";

type MainHeaderSearchbarProps = {
    placeholder: string;
    path: string;
    active: boolean;
    onOpen: () => void;
    onClose: () => void;
}

const MainHeaderSearchbar: FunctionComponent<MainHeaderSearchbarProps> = ({placeholder, path, active, onOpen, onClose}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const history = useHistory();
    const location = useLocation();
    const [isShowSearchTypes, setShowSearchTypes] = useState(false);
    const [query, setQuery] = useState<string>("");

    const onChangeQuery = ({target}: ChangeEvent<HTMLInputElement>) => {
        setQuery(target.value);
        setShowSearchTypes(target.value !== "");
    };

    const handleSearch = () => {
        history.push(`${path}?q=${query || ""}`);
        onClose();
        setShowSearchTypes(false);
    };

    const handleFocus = () => {
        onOpen();
        if (!!query) setShowSearchTypes(true);
    };

    useEffect(() => {
        setQuery(QueryString.getParam(location.search, "q"));
    }, [location]);

    return (
        <>
            <MainHeaderItem
                icon={Action.SEARCH.icon}
                label={textMain("menu.search.title")}
                active={active}
                onOpen={onOpen}
                onClose={onClose}
                mobileOnly
                showLabel={true}
            />
            <div
                className={clsx("main-header__searchbar", {"main-header__searchbar--active": active})}
                onClick={handleFocus}
                onFocus={handleFocus}
                onMouseLeave={() => setShowSearchTypes(false)}
            >
                <Icon {...Action.SEARCH.icon}/>
                <input
                    type="text"
                    autoComplete="off"
                    placeholder={placeholder}
                    value={query || ""}
                    className={clsx("main-header__searchbar__input", {"main-header__searchbar__input--opened": active && isShowSearchTypes})}
                    onKeyDown={(e) => onKeyEnter(e, handleSearch)}
                    onChange={onChangeQuery}
                />
                <ul className="main-header__searchbar__results" onBlur={() => setShowSearchTypes(false)}>
                    <li className="main-header__searchbar__results__item--selected" onClick={handleSearch}>
                        <span dangerouslySetInnerHTML={{__html: textMain("menu.search.search_for", {query: query})}}/><Icon name="trending_flat"/>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default MainHeaderSearchbar;
