import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import clsx from "clsx";
import {LoggedAccount} from "../../api/interface/LoggedAccount";
import {useHistory} from "react-router-dom";
import {Button, ButtonSize, ButtonStyle, Icon} from "@sirdata/ui-lib";
import {pathLogin, TranslationCommonFile} from "../../utils/constants";
import {PORTAL} from "../../../utils/Portal";
import {Origin} from "../../api/model/Origin";
import {usePreprod} from "../../utils/helper";
import {UIEventManager} from "../../utils/UIEventManager";

export type MainHeaderAccountMenuProps = {
    account: LoggedAccount;
};

const MainHeaderAccountMenu: FunctionComponent<MainHeaderAccountMenuProps> = ({account}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const history = useHistory();
    const [isActive, setActive] = useState<boolean>(false);

    const handleLogout = () => {
        session.logout().then(() => {
            session.clean();
            history.push(pathLogin);
        });
    };

    const handleMouseLeave = () => {
        setActive(false);
    };

    useEffect(() => {
        UIEventManager.addListener(MainHeaderAccountMenuUiEvent, () => {
            setActive(true);
        });
        return () => UIEventManager.removeAllListeners(MainHeaderAccountMenuUiEvent);
    }, [account]);

    return (
        <div className={clsx("main-header__account-menu", {"main-header__account-menu--active": isActive})} onMouseLeave={handleMouseLeave}>
            <div className="main-header__account-menu__overlay"/>
            <div className="main-header__account-menu__content">
                <div className="main-header__account-menu__content__row">
                    <div className={clsx("main-header__account-menu__content__avatar")}>
                        <span>{account.initials || <Icon name="person"/>}</span>
                    </div>
                    <div className="main-header__account-menu__content__name">{account.fullName}</div>
                    <div className="main-header__account-menu__content__label">{account.email}</div>
                </div>
                <div className="main-header__account-menu__content__row">
                    <div className="main-header__account-menu__content__label">{textMain("menu.account.id")}</div>
                    <div className="main-header__account-menu__content__label-big">{account.id}</div>
                </div>
                {!PORTAL.isInternal &&
                    <div className="main-header__account-menu__content__row">
                        <Button size={ButtonSize.SMALL} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => window.open(Origin.ACCOUNT.getServiceUrl(usePreprod), "_blank")}>
                            {textMain("menu.account.manage_account")}
                        </Button>
                    </div>
                }
                <div className="main-header__account-menu__content__separator"/>
                <div className="main-header__account-menu__content__row">
                    <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_GREEN} onClick={handleLogout}>{textMain("menu.account.logout")}</Button>
                </div>
            </div>
        </div>
    );
};

export default MainHeaderAccountMenu;
export const MainHeaderAccountMenuUiEvent = "MainHeaderAccountMenu";
