import {AxiosError} from "axios";
import {CommonApiClient, SirdataApiEvent} from "./CommonApiClient";
import {LoggedAccount} from "./interface/LoggedAccount";
import {Cookie} from "./model/Cookie";
import {News} from "./model/portal/News";
import {RestAccount} from "./rest/RestAccount";
import {RestLicense} from "./rest/RestLicenses";
import {RestPortal} from "./rest/RestPortal";
import {RestUserNotifications} from "./rest/RestUserNotifications";

export declare type SirdataEventHandler = (error: AxiosError, args?: {}) => void;

export class CommonApiSession {

    _commonClient: CommonApiClient;
    _tokenCookie: string;

    private _token: string | undefined;
    private _account: LoggedAccount | undefined;
    private _newses: News[] | undefined;
    private _partnerId: number | undefined;

    private _restAccount: RestAccount | undefined;
    private _restLicense: RestLicense | undefined;
    private _restUserNotifications: RestUserNotifications | undefined;
    private _restPortal: RestPortal | undefined;

    constructor(tokenCookie?: string) {
        this._commonClient = new CommonApiClient();
        this._tokenCookie = tokenCookie || "s_token";
    }

    get restAccount(): RestAccount {
        return this._restAccount !== undefined ? this._restAccount : this._restAccount = new RestAccount(this._commonClient);
    }

    get restLicense(): RestLicense {
        return this._restLicense !== undefined ? this._restLicense : this._restLicense = new RestLicense(this._commonClient);
    }

    get restPortal(): RestPortal {
        return this._restPortal !== undefined ? this._restPortal: this._restPortal = new RestPortal(this._commonClient);
    }

    get restUserNotifications(): RestUserNotifications {
        return this._restUserNotifications !== undefined ? this._restUserNotifications : this._restUserNotifications = new RestUserNotifications(this._commonClient);
    }

    async loadAccount() {
        const account = await this.restAccount.get();
        if (!account.id) {
            this.clean();
            return;
        }
        this._account = account;
        this.setPartnerId(account.id);
    }

    async isLogged() {
        return this._commonClient.checkLogged();
    }

    clean() {
        Cookie.remove(this._tokenCookie);
        this._token = undefined;
        this._account = undefined;
        this._partnerId = undefined;
    }

    get token(): string | undefined {
        return this._token;
    }

    setToken(token: string | undefined) {
        this._token = token;
    }

    get partnerId(): number | undefined {
        return this._partnerId;
    }

    setPartnerId(id: number | undefined) {
        this._partnerId = id;
    }

    async loginWithToken(tmpToken: string, remember: boolean): Promise<CommonApiSession> {
        await this._commonClient.loginWithToken(tmpToken);
        if (this._token) {
            const date = new Date();
            date.setDate(date.getDate() + 30);
            Cookie.write(this._tokenCookie, this._token, remember ? date : undefined);
        } else {
            Cookie.remove(this._tokenCookie);
        }
        return this;
    }

    async logout(): Promise<void> {
        await this._commonClient.logout();
    }

    async getAccount(): Promise<LoggedAccount> {
        if (this._account === undefined) {
            await this.loadAccount();
        }
        return this._account as LoggedAccount;
    }

    setAccount(account: LoggedAccount | undefined) {
        this._account = account;
    }

    async getNewses(): Promise<News[]> {
        if (!this._newses) {
            this._newses = await this.restPortal.getNews();
        }
        return this._newses;
    }

    on(eventType: SirdataApiEvent, f: SirdataEventHandler) {
        this._commonClient.events.on(eventType, (err: AxiosError, args?: {}) => {
            f(err, args);
        });
    }

    emit(eventType: SirdataApiEvent) {
        this._commonClient.events.emit(eventType);
    }
}
