import {ApiModel} from "../ApiModel";
import {EmailInfo} from "./EmailInfo";

export class ContactRequest extends ApiModel {
    email_info: EmailInfo = new EmailInfo();
    topic: string = "";
    message: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            email_info: this.email_info,
            topic: this.topic,
            message: this.message
        };
    }
}
