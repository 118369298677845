import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {pathInstallation, pathSites, TranslationPortalFile} from "../utils/constants";
import {MainContent, Wrapper} from "../common/component/widget";
import {
    Action,
    AlertItem,
    AlertSeverity,
    Button,
    ButtonAdd,
    ButtonSize,
    ButtonStyle,
    ContentBlock,
    EmptyContentMessage,
    LayoutRows,
    Loadable,
    MainAlert,
    MainContentPageHeader,
    MainContentStyle,
    ModalConfirmMessage,
    SearchError,
    SearchToolbar,
    SvgRocket,
    Table,
    TableColumnStyle,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {SiteTableRow} from "../component/snippet";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";
import {Site} from "../api/model/site/Site";
import {session} from "../api/ApiSession";
import {SiteQuery} from "../api/model/site/search/SiteQuery";
import {ModalConfigMode, ModalCreateSite, ModalEditSiteInfo, ModalEditSitePrivacy, ModalSettingsExplanation} from "../component/modal";
import {MainHeader} from "../common/component/snippet";
import {ConfigurationModeName} from "../utils/ConfigurationMode";
import {PortalQueryString} from "../api/model/PortalQueryString";
import {PathHelper} from "../common/utils/PathHelper";

enum SitesModal {
    CONFIG_MODE,
    CREATE_SITE,
    EDIT_SITE_INFO,
    SETTINGS_EXPLANATION,
    EDIT_SITE_PRIVACY,
    DELETE_SITE
}

function Sites() {
    const history = useHistory();
    const location = useLocation();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSites} = useTranslation(TranslationPortalFile.SITES);
    const [message, setMessage] = useState<AlertItem>();
    const [isLoading, setLoading] = useState(true);

    const [sites, setSites] = useState<Site[]>([]);
    const [currentSites, setCurrentSites] = useState<Site[]>([]);
    const [query, setQuery] = useState<string>("");
    const MAX_ROWS_PER_PAGE = 20;

    const [activeSite, setActiveSite] = useState<Site>();
    const [activeModal, setActiveModal] = useState<SitesModal>();
    const [activeConfigurationMode, setActiveConfigurationMode] = useState<ConfigurationModeName>();

    useEffect(() => {
        let currentSites = (sites || []);
        if (query) {
            currentSites = currentSites.filter((site) => (`${site.id} ${site.name}`).toLowerCase().includes(query.toLowerCase()));
        }
        setCurrentSites(currentSites);
    }, [sites, query]);

    const loadSites = async () => {
        try {
            setMessage(undefined);
            setLoading(true);
            const newSites = await session.restSite.getSites(new SiteQuery());
            setSites(newSites);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                setMessage({text: textSites("error.get_sites"), severity: AlertSeverity.DANGER});
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteSite = async () => {
        if (!activeSite) return;
        setMessage(undefined);
        try {
            await session.restSite.delete(activeSite.id.toString());
            setActiveSite(undefined);
            closeModal();
        } catch (e) {
            setMessage({text: textSites("error.delete_site"), severity: AlertSeverity.DANGER});
        } finally {
            await loadSites();
        }
    };

    const handleAddSite = () => {
        setActiveModal(SitesModal.CONFIG_MODE);
    };

    const handleEditSiteInfo = async (site?: Site) => {
        await loadSites();
        if (activeConfigurationMode) {
            setActiveSite(site);
            setActiveModal(SitesModal.SETTINGS_EXPLANATION);
        } else {
            closeModal();
        }
    };

    const handleSettingsExplanation = () => {
        if (activeConfigurationMode) {
            if (activeConfigurationMode === ConfigurationModeName.FLASH && activeSite) {
                goToInstallation(activeSite.id);
            } else {
                setActiveModal(SitesModal.EDIT_SITE_PRIVACY);
            }
        } else {
            closeModal();
        }
    };

    const handleEditSitePrivacy = async () => {
        if (activeConfigurationMode === ConfigurationModeName.ADVANCED && activeSite) {
            goToInstallation(activeSite.id);
        } else {
            await loadSites();
            closeModal();
        }
    };

    const closeModal = () => {
        setActiveModal(undefined);
        setActiveSite(undefined);
        setActiveConfigurationMode(undefined);
    };

    const goToInstallation = (siteId: number) => {
        history.push(PathHelper.buildPathWithId(pathInstallation, siteId));
    };

    const handleConfigModeChoice = (mode: ConfigurationModeName) => {
        setActiveConfigurationMode(mode);
        setActiveModal(SitesModal.CREATE_SITE);
    };

    useEffect(() => {
        (async function () {
            await loadSites();

            const configurationMode = PortalQueryString.getParam(location.search, PortalQueryString.SETUP);
            if (!!configurationMode) {
                handleConfigModeChoice(configurationMode as ConfigurationModeName);
            }
            history.replace(pathSites);
        })(); // eslint-disable-next-line
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textSites("title")}/>
                <LayoutRows>
                    <MainAlert content={message}/>
                    <Loadable loading={isLoading}>
                        {!!sites.length ?
                            <ContentBlock>
                                <SearchToolbar
                                    searchBar={{placeholder: textSites("search_for_site"), value: query, onChange: setQuery}}
                                    actions={[<ButtonAdd key={0} size={ButtonSize.MEDIUM} onClick={handleAddSite}/>]}
                                />
                                {!!currentSites.length ?
                                    <Table
                                        columns={[
                                            {label: textSites("field.status"), width: 2},
                                            {label: textSites("field.id"), width: 10},
                                            {label: textSites("field.site_name"), width: 15},
                                            {label: textSites("field.domain"), width: 15, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {label: textSites("field.actions"), width: 2, styles: TableColumnStyle.ALIGN_CENTER}
                                        ]}
                                        pagination={MAX_ROWS_PER_PAGE}
                                    >
                                        {currentSites.map((site) =>
                                            <SiteTableRow
                                                key={site.id}
                                                site={site}
                                                actions={{
                                                    onEditInfo: () => {
                                                        setActiveSite(site);
                                                        setActiveModal(SitesModal.EDIT_SITE_INFO);
                                                    },
                                                    onEditSettings: () => {
                                                        setActiveSite(site);
                                                        setActiveModal(SitesModal.EDIT_SITE_PRIVACY);
                                                    },
                                                    onRetrieveCode: () => {
                                                        goToInstallation(site.id);
                                                    },
                                                    onDelete: () => {
                                                        setActiveSite(site);
                                                        setActiveModal(SitesModal.DELETE_SITE);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Table> :
                                    <SearchError query={query}/>
                                }
                            </ContentBlock> :
                            <EmptyContentMessage svg={SvgRocket} message={textSites("empty_content.message")}>
                                <Button onClick={handleAddSite} size={ButtonSize.BIG}>
                                    {textSites("empty_content.start")}
                                </Button>
                            </EmptyContentMessage>
                        }
                    </Loadable>
                </LayoutRows>
                <ModalConfigMode active={activeModal === SitesModal.CONFIG_MODE} onSubmit={handleConfigModeChoice} onClose={closeModal}/>
                <ModalCreateSite active={activeModal === SitesModal.CREATE_SITE} onSubmit={handleEditSiteInfo} onClose={closeModal}/>
                <ModalEditSiteInfo site={activeModal === SitesModal.EDIT_SITE_INFO ? activeSite : undefined} onSubmit={handleEditSiteInfo} onClose={closeModal}/>
                <ModalSettingsExplanation active={activeModal === SitesModal.SETTINGS_EXPLANATION} configurationMode={activeConfigurationMode} onContinue={handleSettingsExplanation} onClose={closeModal}/>
                <ModalEditSitePrivacy
                    site={activeSite && activeModal === SitesModal.EDIT_SITE_PRIVACY ? activeSite : undefined}
                    onSubmit={handleEditSitePrivacy}
                    onClose={closeModal}
                    isCreationWorkflow={activeConfigurationMode === ConfigurationModeName.ADVANCED}
                    onBack={() => setActiveModal(SitesModal.EDIT_SITE_INFO)}
                />
                <ModalConfirmMessage
                    active={activeModal === SitesModal.DELETE_SITE}
                    message={textSites("modal.delete.warning")}
                    cancel={{onClick: closeModal, style: ButtonStyle.PRIMARY_MIDNIGHT, children: textCommon(Action.CANCEL.labelKey)}}
                    confirm={{onClick: handleDeleteSite, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(Action.DELETE.labelKey)}}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Sites;
