import {
    Button,
    ButtonSize,
    ButtonStyle,
    Color,
    MainHeaderAccount,
    MainHeaderAccountAvatar,
    MainHeaderBurgerMenu,
    MainHeaderItem,
    MainHeaderLayout,
    MainHeaderLogo,
    MainHeaderShortcutMenu,
    MainHeaderSlice,
    MenuItem,
    ModalConfirmMessage,
    TranslationLibFile,
    TranslationLibKey
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {PORTAL} from "../../../utils/Portal";
import {LoggedAccount} from "../../api/interface/LoggedAccount";
import {Origin} from "../../api/model/Origin";
import {pathLogin, TranslationCommonFile} from "../../utils/constants";
import {HeaderModule} from "../../utils/HeaderModule";
import {usePreprod} from "../../utils/helper";
import {UIEventManager} from "../../utils/UIEventManager";
import {ModalContact} from "../modal";
import {ModalContactUiEvent} from "../modal/ModalContact";
import {MainHeaderAccountMenuUiEvent} from "./MainHeaderAccountMenu";
import {MainHeaderAccountMenu, MainHeaderNotifications, MainHeaderOriginMenu, MainHeaderSearchbar} from "./index";
import {MainHeaderNotificationMenuUiEvent} from "./MainHeaderNotificationMenu";
import {MainHeaderOriginMenuUiEvent} from "./MainHeaderOriginMenu";
import {Theme} from "../../utils/Theme";

type MainHeaderProps = {
    preventUnsaved?: boolean;
    onSave?: () => void;
}

const MainHeader: FunctionComponent<MainHeaderProps> = ({preventUnsaved, onSave}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const history = useHistory();
    const location = useLocation();
    const activePath = location.pathname;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [hasPortalAccess, setPortalAccess] = useState(false);
    const [account, setAccount] = useState<LoggedAccount>();
    const [goTo, setGoTo] = useState<string>("");
    const [activeModule, setActiveModule] = useState<HeaderModule | "menu" | "apps" | "">("");

    const handleLink = (path: string, e?: React.MouseEvent) => {
        if (e?.ctrlKey || e?.metaKey) {
            window.open(path, "_blank");
        } else {
            if (preventUnsaved) {
                setGoTo(path);
            } else {
                history.push(path);
            }
        }
    };

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getAccount();
                setAccount(account);
                setPortalAccess(account.hasAccess(PORTAL.origin.name));
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const filterMenuItems = (menuItems: MenuItem[], account?: LoggedAccount): MenuItem[] => {
        if (!account) return [];
        return menuItems.filter(({allowedTo, items}) => {
            if (allowedTo) {
                return account.hasAuthorization(allowedTo);
            }

            const requiredAuthorizations = items?.filter((it) => it.allowedTo).map((it) => it.allowedTo);
            return account.hasAnyOfAuthorizations(requiredAuthorizations as string[]);
        });
    };

    const buildMenuItems = (items: MenuItem[]) => {
        return filterMenuItems(items, account)
            .map((item) => {
                item.text = t(`menu.${item.label}`);
                if (item.items) {
                    item.items = buildMenuItems(item.items);
                }
                return item;
            });
    };

    return (
        <MainHeaderLayout themeWhite={PORTAL.theme === Theme.SIRDATA_WHITE}>
            <MainHeaderSlice>
                {hasPortalAccess &&
                <MainHeaderBurgerMenu
                    items={buildMenuItems(PORTAL.menuItems)}
                    activePath={activePath}
                    active={activeModule === "menu"}
                    onOpen={() => setActiveModule("menu")}
                    onClose={() => setActiveModule("")}
                    onClickLink={handleLink}
                    themeWhite={PORTAL.theme === Theme.SIRDATA_WHITE}
                />
                }
                <MainHeaderLogo link={account ? PORTAL.defaultPath : pathLogin} logo={PORTAL.theme.logo} onClick={handleLink} usePreprod={usePreprod}/>
            </MainHeaderSlice>
            {!isLoading &&
            (account ?
                <>
                    <MainHeaderSlice>
                        {(hasPortalAccess && !!PORTAL.shortcutMenuItems.length) &&
                        <MainHeaderShortcutMenu
                            items={buildMenuItems(PORTAL.shortcutMenuItems)}
                            activePath={activePath}
                            onClickLink={handleLink}
                            themeWhite={PORTAL.theme === Theme.SIRDATA_WHITE}
                        />
                        }
                        {(hasPortalAccess && PORTAL.hasModule(HeaderModule.SEARCH)) &&
                        <>
                            <MainHeaderSearchbar
                                placeholder={t(PORTAL.searchOptions.titleTranslationKey)}
                                path={PORTAL.searchOptions.path}
                                active={activeModule === HeaderModule.SEARCH}
                                onOpen={() => setActiveModule(HeaderModule.SEARCH)}
                                onClose={() => setActiveModule("")}
                            />
                            {PORTAL.searchOptions.alternateLink &&
                                <>
                                    <span style={{color: Color.MIDNIGHT_LIGHTER}}>{textMain("menu.search.or")}</span>
                                    <MainHeaderItem
                                        icon={{name: PORTAL.searchOptions.alternateLink.icon}}
                                        label={t(PORTAL.searchOptions.alternateLink.label)}
                                        active={activePath.includes(PORTAL.searchOptions.alternateLink.path)}
                                        onOpen={() => handleLink(PORTAL.searchOptions.alternateLink?.path || "")}
                                        showLabel
                                    />
                                </>
                            }
                        </>
                        }
                    </MainHeaderSlice>
                    <MainHeaderSlice>
                        {hasPortalAccess &&
                        PORTAL.shortcuts.map(({target, label, icon}) =>
                            <MainHeaderItem
                                key={label}
                                icon={icon}
                                label={t(label)}
                                active={typeof target === "string" && activePath.includes(target)}
                                onOpen={typeof target === "string" ? () => handleLink(target) : target}
                            />
                        )}
                        {PORTAL.hasModule(HeaderModule.CONTACT) &&
                        <MainHeaderItem
                            icon={{name: "mail"}}
                            label={textMain("menu.contact")}
                            active={activeModule === HeaderModule.CONTACT}
                            onOpen={() => {
                                UIEventManager.emit(ModalContactUiEvent, {onClose: () => setActiveModule("")});
                                setActiveModule(HeaderModule.CONTACT);
                            }}
                        />
                        }
                        {(hasPortalAccess && PORTAL.hasModule(HeaderModule.NOTIFICATIONS)) &&
                        <MainHeaderNotifications
                            active={activeModule === HeaderModule.NOTIFICATIONS}
                            onOpen={() => {
                                UIEventManager.emit(MainHeaderNotificationMenuUiEvent, () => setActiveModule(""));
                                setActiveModule(HeaderModule.NOTIFICATIONS);
                            }}
                        />
                        }
                        <MainHeaderAccount themeWhite={PORTAL.theme === Theme.SIRDATA_WHITE}>
                            {PORTAL.isInternal ?
                                <MainHeaderItem
                                    icon={{name: "apps"}}
                                    label={textMain("menu.modules")}
                                    active={activeModule === "apps"}
                                    onOpen={() => {
                                        UIEventManager.emit("ModalModules", () => setActiveModule(""));
                                        setActiveModule("apps");
                                    }}
                                /> :
                                <MainHeaderItem
                                    icon={{name: "apps"}}
                                    label={textMain("menu.applications")}
                                    active={activeModule === "apps"}
                                    onOpen={() => {
                                        UIEventManager.emit(MainHeaderOriginMenuUiEvent, () => setActiveModule(""));
                                        setActiveModule("apps");
                                    }}
                                >
                                    <MainHeaderOriginMenu activeOrigins={Origin.values().filter((origin) => account.hasAccess(origin.name))}/>
                                </MainHeaderItem>
                            }
                            <MainHeaderAccountAvatar
                                initials={account.initials}
                                tooltip={textMain("menu.user_account", {id: account.id, name: account.fullName, email: account.email})}
                                onOpen={() => {
                                    UIEventManager.emit(MainHeaderAccountMenuUiEvent);
                                    setActiveModule("");
                                }}
                            >
                                <MainHeaderAccountMenu account={account}/>
                            </MainHeaderAccountAvatar>
                        </MainHeaderAccount>
                    </MainHeaderSlice>
                </> :
                <MainHeaderSlice>
                    <Button onClick={() => history.push(pathLogin)} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} icon={{name: "person"}}>
                        {textMain("menu.my_account")}
                    </Button>
                </MainHeaderSlice>
            )}
            <ModalConfirmMessage
                active={!!goTo}
                cancel={{onClick: () => setGoTo(""), size: ButtonSize.BIG, style: ButtonStyle.PRIMARY_MIDNIGHT, children: textCommon(TranslationLibKey.ACTION_CANCEL)}}
                confirm={{onClick: () => history.push(goTo), size: ButtonSize.BIG, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(TranslationLibKey.ACTION_LEAVE)}}
                message={textCommon(TranslationLibKey.MESSAGE_WARNING_UNSAVED_CHANGES)}
            />
            <ModalContact/>
        </MainHeaderLayout>
    );
};

export default MainHeader;
