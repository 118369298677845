import {Alert, AlertItem, AlertSeverity, CodeBlock, CodeBlockLanguage, FieldBlock, FlexContent, FlexContentDirection, FlexContentSpacing, LayoutRows, Loadable, MainAlert, MainContentPageHeader, MainContentStyle, Paragraph} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {session} from "../api/ApiSession";
import {Site} from "../api/model/site/Site";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";
import {MainHeader} from "../common/component/snippet";
import {MainContent, Wrapper} from "../common/component/widget";
import {pathError404} from "../common/utils/constants";
import {AnswerButtons, InstallationStep, InstallationSteps, PrivacyRecommendation} from "../component/snippet";
import {TranslationPortalFile} from "../utils/constants";
import {configGaTagsInGtmWithCookielessUrl, configGaTagsInGtmWithoutCookielessUrl} from "../utils/helper";

enum QuestionName {
    GTM_ONLY = "gtm-only",
    GTM_ONLY_COOKIELESS = "gtm-only-cookieless"
}

enum GtmOnlyAnswer {
    YES = "YES",
    NO = "NO"
}

enum GtmOnlyCookielessAnswer {
    YES = "YES",
    NO = "NO"
}

function Installation() {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const {t: textInstallation} = useTranslation(TranslationPortalFile.INSTALLATION);

    const [site, setSite] = useState<Site>();
    const [message, setMessage] = useState<AlertItem>();
    const [isLoading, setLoading] = useState(true);
    const [isChoosing, setChoosing] = useState(false);

    const [gtmOnly, setGtmOnly] = useState<string>(window.sessionStorage.getItem(QuestionName.GTM_ONLY) || "");
    const [gtmOnlyCookieless, setGtmOnlyCookieless] = useState<string>(window.sessionStorage.getItem(QuestionName.GTM_ONLY_COOKIELESS) || "");

    useEffect(() => {
        (async function () {
            try {
                setMessage(undefined);
                setLoading(true);
                setSite(await session.restSite.getSite(params.id));
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    history.push(pathError404);
                } else {
                    setMessage({text: textInstallation("error.get_site"), severity: AlertSeverity.DANGER});
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [params.id, textInstallation, history]);

    useEffect(() => window.sessionStorage.setItem(QuestionName.GTM_ONLY, gtmOnly), [gtmOnly]);
    useEffect(() => window.sessionStorage.setItem(QuestionName.GTM_ONLY_COOKIELESS, gtmOnlyCookieless), [gtmOnlyCookieless]);

    const handleChange = (name: string, value: string) => {
        setChoosing(true);

        if (name === QuestionName.GTM_ONLY) setGtmOnly(value);
        if (name === QuestionName.GTM_ONLY_COOKIELESS) setGtmOnlyCookieless(value);

        setTimeout(() => {
            setChoosing(false);
        }, 200);
    };

    /* eslint-disable */
    const procedure = {
        gtmOnly: [
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.step_1.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.step_1.description`, {pa: site?.partner_id, c: site?.id, url: configGaTagsInGtmWithoutCookielessUrl})}}/></Paragraph>,
                    <CodeBlock header={textInstallation("code_snippet")} code={`Partner ID: ${site?.partner_id}\nConf ID: ${site?.id}`} copiable/>
                ]
            }
        ],
        gtmOnlyCookieless: [
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_1.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_1.description`)}}/></Paragraph>,
                    <CodeBlock header={textInstallation("code_snippet")} language={CodeBlockLanguage.HTML} code={site?.tag} copiable/>
                ]
            },
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_2.title`),
                content: [
                    <img
                        src={`/images/${textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_2.image`)}`}
                        alt={textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_2.image`).split(".")[0].replaceAll("_", " ")}
                    />
                ]
            },
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_3.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_3.description`, {url: configGaTagsInGtmWithCookielessUrl})}}/></Paragraph>
                ]
            },
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_4.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.YES}.cookieless.step_4.description`)}}/></Paragraph>,
                    <PrivacyRecommendation/>
                ]
            }
        ],
        noGtmOnly: [
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_1.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_1.description_1`)}}/></Paragraph>,
                    <CodeBlock header={textInstallation("code_snippet")} language={CodeBlockLanguage.HTML} code={site?.legacy_tag} copiable/>,
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_1.description_2`)}}/></Paragraph>
                ]
            },
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_2.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_2.description`, {url: configGaTagsInGtmWithoutCookielessUrl})}}/></Paragraph>,
                    <Alert text={textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_2.warning`)} severity={AlertSeverity.WARNING}/>
                ]
            },
            {
                title: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_3.title`),
                content: [
                    <Paragraph><span dangerouslySetInnerHTML={{__html: textInstallation(`installation.gtm_only.${GtmOnlyAnswer.NO}.step_3.description`)}}/></Paragraph>,
                    <PrivacyRecommendation/>
                ]
            }
        ]
    };
    /* eslint-enable */

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textInstallation("title")}/>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        {message ? (
                            <MainAlert content={message}/>
                        ) : (
                            <>
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                                    <Paragraph>{textInstallation("description")}</Paragraph>
                                    <FieldBlock label={textInstallation("question.gtm_only.label")}>
                                        <AnswerButtons
                                            name={QuestionName.GTM_ONLY}
                                            onChange={(value) => handleChange(QuestionName.GTM_ONLY, value as string)}
                                            options={Object.values(GtmOnlyAnswer).map((value) =>
                                                ({label: textInstallation(`question.gtm_only.answer.${value}`), value})
                                            )}
                                            value={gtmOnly}
                                        />
                                    </FieldBlock>
                                    {gtmOnly === GtmOnlyAnswer.YES &&
                                        <FieldBlock label={textInstallation("question.cookieless.label")}>
                                            <AnswerButtons
                                                name={QuestionName.GTM_ONLY_COOKIELESS}
                                                onChange={(value) => handleChange(QuestionName.GTM_ONLY_COOKIELESS, value as string)}
                                                options={Object.values(GtmOnlyCookielessAnswer).map((value) =>
                                                    ({label: textInstallation(`question.cookieless.answer.${value}`), value})
                                                )}
                                                value={gtmOnlyCookieless}
                                            />
                                        </FieldBlock>
                                    }
                                </FlexContent>
                                <Loadable loading={isChoosing} loaderOptions={{small: true, cssClass: "installation-loader"}}>
                                    {gtmOnly &&
                                        <InstallationSteps>
                                            {[GtmOnlyAnswer.NO].includes(gtmOnly as GtmOnlyAnswer) &&
                                                <>
                                                    {procedure.noGtmOnly.map((step, index) =>
                                                        <InstallationStep key={`noGtmOnly-${index + 1}`} index={index + 1} title={step.title}>
                                                            {step.content.map((element, index) => React.cloneElement(element, {key: `noGtmOnly-content-${index + 1}`}))}
                                                        </InstallationStep>
                                                    )}
                                                </>
                                            }
                                            {[GtmOnlyAnswer.YES].includes(gtmOnly as GtmOnlyAnswer) &&
                                                <>
                                                    {[GtmOnlyCookielessAnswer.YES].includes(gtmOnlyCookieless as GtmOnlyCookielessAnswer) &&
                                                        <>
                                                            {procedure.gtmOnlyCookieless.map((step, index) =>
                                                                <InstallationStep key={`gtmOnlyCookieless-${index + 1}`} index={index + 1} title={step.title}>
                                                                    {step.content.map((element, index) => React.cloneElement(element, {key: `gtmOnlyCookieless-content-${index + 1}`}))}
                                                                </InstallationStep>
                                                            )}
                                                        </>
                                                    }
                                                    {[GtmOnlyCookielessAnswer.NO].includes(gtmOnlyCookieless as GtmOnlyCookielessAnswer) &&
                                                        <>
                                                            {procedure.gtmOnly.map((step, index) =>
                                                                <InstallationStep key={`gtmOnly-${index + 1}`} index={index + 1} title={step.title}>
                                                                    {step.content.map((element, index) => React.cloneElement(element, {key: `gtmOnly-content-${index + 1}`}))}
                                                                </InstallationStep>
                                                            )}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </InstallationSteps>
                                    }
                                </Loadable>
                            </>
                        )}
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Installation;
