export class Locale {
    static FRENCH = "fr";
    static ENGLISH = "en";

    private static supportedLocales = [
        Locale.FRENCH,
        Locale.ENGLISH
    ];
    private static currentLocale = navigator && (navigator.language || (navigator.languages && navigator.languages[0]) || Locale.FRENCH).toLowerCase().substr(0, 2);

    static getCurrentLocale(): string {
        return this.supportedLocales.find((it) => it === this.currentLocale) || Locale.ENGLISH;
    }

    static isFrench(): boolean {
        return this.getCurrentLocale() === this.FRENCH;
    }
}
