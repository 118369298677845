import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {session} from "../api/ApiSession";
import {QueryString} from "../common/api/model/QueryString";
import {TranslationPortalFile} from "../utils/constants";
import {usePreprod} from "../common/utils/helper";
import {Action, Alert, Box, Button, ButtonLink, ButtonStyle, Color, Footer, HeroHeader, Icon, SvgSirdataLogoWhite} from "@sirdata/ui-lib";
import {PORTAL} from "../utils/Portal";
import {Wrapper} from "../common/component/widget";
import {sirdataHelperPricingUrl} from "../utils/helper";
import {pathAccess} from "../common/utils/constants";

function Login() {
    const history = useHistory();
    const {t: textLogin} = useTranslation(TranslationPortalFile.LOGIN);
    const {t: textTranslation} = useTranslation(TranslationPortalFile.TRANSLATION);

    const goToAccount = (path: string) => () => {
        const accountRoot = (usePreprod ? "https://account-preprod.sirdata.io" : "https://account.sirdata.io");
        window.location.href = `${accountRoot}/${path}?${QueryString.build({[QueryString.ORIGIN]: PORTAL.origin.name})}`;
    };

    useEffect(() => {
        if (session) {
            (async () => {
                try {
                    const isLogged = await session.isLogged();
                    if (!isLogged) {
                        return;
                    }

                    const account = await session.getAccount();
                    if (!account.hasAccess(PORTAL.origin.name)) {
                        history.push(pathAccess);
                        return;
                    }

                    history.push(PORTAL.defaultPath);
                } catch {
                    session.clean();
                }
            })();
        }
    }, [history]);

    const buildProductListItems = () => (
        <div className="login-items">
            <ul>
                {(textLogin("teaser.items", {returnObjects: true}) as string[]).map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </div>
    );

    const buildFooter = () => (
        <Footer
            legalNotice={PORTAL.footer.legalNotice}
            cookiesSettings={PORTAL.footer.cookiesSettings}
            items={PORTAL.footer.items?.map((item) => ({path: item.path, label: textTranslation(`footer.${item.label}`)}))}
        />
    );

    return (
        <Wrapper>
            <Button
                style={ButtonStyle.PRIMARY_WHITE}
                onClick={goToAccount("login")}
                cssClass="login-button"
            >
                {textLogin("login.buttons.login")}
            </Button>
            <div className="login-left">
                <div className="login-left-content">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <h1 className="login-title">{textLogin("teaser.title")}</h1>
                    {buildProductListItems()}
                </div>
            </div>
            <div className="login-right">
                <div className="login-right__content">
                    <HeroHeader title={textLogin("login.title")} cssClass="login-right__content__hero-title"/>
                    <div className="login-right__content__separator">
                        <Icon name="more_vert" colorIcon={Color.GREY}/>
                    </div>
                    <div className="login-right__content__items">
                        <Box cssClass="login-right__content__items__item">
                            <div>
                                <span className="h1">{textTranslation("solution.title")}</span>
                                <Alert text={textTranslation("free_trial")}/>
                            </div>
                            <div>
                                <div className="login-right__content__items__item__tax">
                                    <span>{textTranslation("solution.price_before")}</span>
                                    <span className="login-right__content__items__item__tax__price">{textTranslation("solution.price")}</span>
                                    <span>{textTranslation("solution.price_after")}</span>
                                </div>
                                <Button icon={{name: "east"}} reverse style={ButtonStyle.PRIMARY_GREEN} onClick={goToAccount("register")}>{textLogin("login.buttons.start")}</Button>
                            </div>
                        </Box>
                    </div>
                    <ButtonLink
                        cssClass="login-right__content__see-more"
                        icon={Action.OPEN.icon}
                        reverseUnderline
                        onClick={() => window.open(sirdataHelperPricingUrl, "_blank")}
                    >
                        {textLogin("login.buttons.see_more").toUpperCase()}
                    </ButtonLink>
                </div>
                <div className="login-right__footer">
                    {buildFooter()}
                </div>
            </div>
            <div className="login-bottom">
                {buildProductListItems()}
                {buildFooter()}
            </div>
        </Wrapper>
    );
}

export default Login;
