import React, {FunctionComponent} from "react";
import {Footer, MainContent as MainContentLib, MainContentStyle} from "@sirdata/ui-lib";
import {MainAlerts, MainModalNotification} from ".";
import {PORTAL} from "../../../utils/Portal";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

export type MainContentProps = {
    children: React.ReactNode;
    style?: MainContentStyle;
    cssClass?: string;
};

const MainContent: FunctionComponent<MainContentProps> = (props) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <>
            <MainContentLib {...props}/>
            <Footer
                legalNotice={PORTAL.footer.legalNotice}
                cookiesSettings={PORTAL.footer.cookiesSettings}
                items={PORTAL.footer.items?.map((item) => ({path: item.path, label: t(`footer.${item.label}`)}))}
            />
            <MainModalNotification/>
            <MainAlerts/>
        </>
    );
};

export default MainContent;
