import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {EmptyContentMessage, MainContentStyle, SvgError403} from "@sirdata/ui-lib";
import {pathLogin, TranslationCommonFile} from "../utils/constants";
import {MainContent, Wrapper} from "../component/widget";
import {MainHeader} from "../component/snippet";

function Error403() {
    const {t: textErrors} = useTranslation(TranslationCommonFile.ERRORS);
    const history = useHistory();

    useEffect(() => {
        (async function () {
            try {
                await session.loadAccount();
            } catch {
                history.push(pathLogin);
            }
        })();
    }, [history]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <EmptyContentMessage svg={SvgError403} message={textErrors("error_403.text")}/>
            </MainContent>
        </Wrapper>
    );
}

export default Error403;
